/* eslint-disable no-undef */
/* eslint no-underscore-dangle: ['error', { 'allow': ['_isScanning', '_shouldScan'] }] */
(
    function ($) {
        var QrCode = {
            $html5QrCodeScanner: null,
            $qrModal: $('.qr-modal'),
            $config: {
                fps: 10,
                qrbox: $('#qr-reader').width() * 0.65
            },
            $currentScannedUrl: null,

            /**
             * Logic to handle a successful scan of a qr code
             *
             * If the scanned qr-code is of the same origin     --> immediate redirect
             * If the scanned qr-code is of a different origin  --> dialog box to agree or disagree
             */
            onScanSuccess: function (qrMessage) {
                if (this.isURL(qrMessage) && this.isSameOrigin(qrMessage)) {
                    this.relocateUser(qrMessage);
                    this.$html5QrCodeScanner.stop();
                } else if (this.isURL(qrMessage) && !this.isSameOrigin(qrMessage)) {
                    this.$qrModal.modal();
                    this.pauseScanning();
                    this.$currentScannedUrl = qrMessage;
                    $('.scanned-qr-url').html(this.$currentScannedUrl);
                }
            },

            /**
             * Empty function, otherwise there is a lot of console fail commands.
             */
            onScanFail: function () {
            },

            /**
             * Check if the current qrcode result is a url
             */
            isURL: function (str) {
                var pattern = new RegExp('^(https?:\\/\\/)?' +          // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +   // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' +                         // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +                     // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' +                            // query string
                '(\\#[-a-z\\d_]*)?$', 'i');                             // fragment locator
                return pattern.test(str);
            },

            /**
             * Check if scanned qr code is of the same origin
             */
            isSameOrigin: function (url) {
                return (url.includes(window.location.origin));
            },

            /**
             * Start the scanner
             */
            startScanning: function () {
                this.$html5QrCodeScanner
                    .start(
                        { facingMode: 'environment' },
                        this.$config,
                        $.proxy(this.onScanSuccess, this),
                        $.proxy(this.onScanFail, this)
                    );
            },
            /**
             * Pause the scanner
             * Extra check if the modal is open
             */
            pauseScanning: function () {
                if (this.$qrModal.hasClass('show')) {
                    this.$html5QrCodeScanner._isScanning = false;
                    this.$html5QrCodeScanner._shouldScan = false;
                }
            },

            /**
             * Resume scanner if user has denied redirect on modal
             */
            resumeScanning: function () {
                this.pauseScanning();
                this.$html5QrCodeScanner.clear();
                this.startScanning();
            },

            /**
             * Redirect the user
             */
            relocateUser: function (url) {
                window.location = url;
            },

            init: function () {
                $('.qr-modal').appendTo('body');

                navigator.mediaDevices.getUserMedia({ video: true })
                    .then($.proxy(function () {
                        this.$html5QrCodeScanner = new Html5Qrcode('qr-reader');
                        this.startScanning();
                        $('.qr-agree').on('click', $.proxy(function () {
                            this.relocateUser(this.$currentScannedUrl);
                        }, this));
                        $('.qr-modal').on('hide.bs.modal', $.proxy(function () {
                            this.resumeScanning();
                        }, this));
                    }, this))
                    .catch(function () {
                        $('.denied-camera').show();
                    });
            }
        };

        /**
         * Added a different check if document is ready
         * because sterc.js already has a window.onload.
         */
        $(document).ready(function () {
            if ($('#qr-reader').length > 0) {
                QrCode.init();
            }
        });
    }(jQuery)
);
