import 'leaflet/dist/leaflet';
import 'pouchdb/dist/pouchdb';
import 'leaflet-sidebar-v2/js/leaflet-sidebar';
import 'leaflet.tilelayer.pouchdbcached/L.TileLayer.PouchDBCached';

/* eslint-disable no-undef */
/* eslint no-underscore-dangle: ['error', { 'allow': ['_northEast'] }] */
/* eslint-disable no-console */
(
    function ($) {
        var Map = {
            downloadMap             : $('[data-map-download-btn]'),
            $downloadProgressBar    : $('[data-map-download-progress]'),
            $amountOfMapTiles       : 4135,
            $percentage             : 0,
            $amountDone             : 0,

            $filtersList            : $('.filters-list'),
            $markersList            : $('.markers-list'),
            $filterNoResults        : $('[data-map-no-results]'),

            $authHeader:
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',

            // Arrays to store the places of interest information.
            $placesOfInterest: null,
            $markerById: [],
            $mapMarkers: [],

            // Location variables
            $currentPosition: null,
            $currentAccuracy: null,

            // Bounding box variables to easily download the map of Ameland.
            $bbox: L.latLngBounds(L.latLng(53.4171, 5.5700), L.latLng(53.4776, 5.9786)),

            $mapId: 'main-map',
            $map: null,
            $bounds: L.latLngBounds(L.latLng(53.4171, 5.5700), L.latLng(53.4776, 5.9786)),

            // Set the tile service.
            $tile_url: 'assets/img/map/tiles/{z}/{x}/{y}.webp',

            $tileLayer: null,

            // Add a FeatureGroup where the map markers will reside.
            $markersLayer: new L.FeatureGroup(),

            /**
             * Loop through list of places of interest, add them to a unordered list element.
             * Add the ul to the sidebar of the map,
             * and a click event on the POI to center map on that POI.
             * @param ul
             * @returns {*}
             */
            fillSidebarWithPlacesOfInterest: function ($ul, $array) {
                $.each(
                    $array,
                    $.proxy(function (i, data) {
                        this.$markerById[i] = {
                            id: data.id,
                            lat: data.address.latitude,
                            lng: data.address.longitude
                        };

                        // Generate UID based on lat/long,
                        // since one POI can occur multiple times, but in a different category
                        var latitude = data.address.latitude;
                        var longitude = data.address.longitude;
                        var markerUid = String(latitude) + String(longitude);

                        if ($('[data-marker-uid="' + markerUid + '"]').length === 0) {
                            $ul.append(
                                $('<li id="' + data.id + '" data-marker-uid="' + markerUid + '">')
                                    .append($('<div class="marker-container">')
                                        .append($('<h5>' + data.title + '</h5>'))
                                        .append($('<p>' + this.getMarkerInfo(data) + '</p>'))
                                    )
                                    .on(
                                        'click',
                                        $.proxy(function (event) {
                                            if (event.target.nodeName === 'I') {
                                                this.showInformation(event.currentTarget.id);
                                            } else {
                                                // Add a click event to the POI element in the list
                                                // to center the map on it.
                                                this.moveToMapMarker(event.currentTarget.id);
                                            }
                                        }, this)
                                    )
                            );
                        }
                    }, this)
                );
            },

            /**
             * Function to show information about a place of interest
             */
            showInformation: function (id) {
                $('[data-map-marker-info]').empty();

                this.$filtersList.hide();
                $markersList.hide();

                $('.marker-info').fadeIn();
                var poi = this.getPlaceOfInterest(id);

                // Create the element with the information about the marker.
                $('[data-map-marker-info]').append(
                    $(
                        '<h2 class="is-h2">' + poi.title + '</h2>' +
                        '<p>' + poi.address.city + '</p>'
                    )
                );
            },

            getPlaceOfInterest: function (id) {
                var placeOfInterest = null;
                for (var i = 0; i < this.$placesOfInterest.length; i++) {
                    if (this.$placesOfInterest[i].id === parseInt(id, 10)) {
                        placeOfInterest = this.$placesOfInterest[i];
                        break;
                    }
                }

                return placeOfInterest;
            },

            getMarkerInfo: function (data) {
                var details = {
                    street: data.address.street,
                    housenumber: data.address.housenumber,
                    zipcode: data.address.zipcode,
                    city: data.address.city,
                    phone: data.phone
                };

                var returnString = '';
                var keys = Object.keys(details);
                var values = Object.values(details);

                for (var i = 0; i < keys.length; i++) {
                    switch (keys[i]) {
                    case 'street':
                        returnString += this.isEmpty(values[i]) ? '' : values[i].trim();
                        break;
                    case 'housenumber':
                        returnString += this.isEmpty(values[i]) ? ',<br />' : ' ' + values[i].trim() + ',<br />';
                        break;
                    case 'zipcode':
                        returnString += this.isEmpty(values[i]) ? '' : values[i].trim();
                        break;
                    case 'city':
                        returnString += this.isEmpty(values[i]) ? ',<br />' : ' ' + values[i].trim();
                        break;
                    case 'phone':
                        returnString += this.isEmpty(values[i]) ? '' : ',<br />' + values[i].trim();
                        break;
                    default:
                        break;
                    }
                }

                return returnString;
            },

            isEmpty: function (str) {
                return str === '' || str === undefined || str === null;
            },

            /**
             * When you click on a map marker in the sidebar,
             * navigate to the position on the map, and open the marker popup.
             * @param id
             */
            moveToMapMarker: function (id) {
                for (var i = 0; i < this.$markerById.length; i++) {
                    if (parseInt(id, 10) === this.$markerById[i].id) {
                        this.$map.flyTo(
                            L.latLng({
                                lat: this.$markerById[i].lat,
                                lng: this.$markerById[i].lng
                            }),
                            17
                        );

                        if (this.$markerById[i].marker) {
                            this.$markerById[i].marker.openPopup();
                        }

                        break;
                    }
                }
            },

            /**
             * Determine location of user, and add a circle on the map.
             * @param e
             */
            onLocationFound: function (e) {
                // If position is defined, remove previous positions
                if (this.$currentPosition) {
                    this.$map.removeLayer(this.$currentPosition);
                    this.$map.removeLayer(this.$currentAccuracy);
                }
                var radius = e.accuracy / 2;

                this.$currentPosition = L.marker(L.latLng(e.latlng), {
                    icon: L.icon({
                        iconUrl: '/assets/img/map/m1.png',
                        iconSize: [20, 20],
                        iconAnchor: [0, 0],
                        popupAnchor: [0, 0]
                    })
                }).addTo(this.$map);
                this.$currentAccuracy = L.circle(e.latlng, radius).addTo(this.$map);
            },

            /**
             * Log to console if retrieving location results in error
             * @param e
             */
            onLocationError: function (e) {
                if (e.message !== 'Geolocation error: Timeout expired.') {
                    console.error(e.message);
                }
            },

            /**
             * Call locate function on map object
             */
            locatePositionOnMap: function () {
                this.$map.locate({ setView: false, maxZoom: 16 });
            },

            // Seed the pouchDB database.
            seed: function () {
                if (localStorage.download !== 'in-progress') {
                    this.setDownloadStatus('in-progress');
                }
            },

            addMarkersToMap: function (markersArray) {
                $.each(
                    markersArray,
                    $.proxy(function (index, data) {
                        // Make a marker and add it to the markerlayer
                        var marker = L.marker(
                            L.latLng({
                                lat: data.address.latitude,
                                lng: data.address.longitude
                            }), {
                                title: data.title,
                                icon: L.icon({
                                    iconUrl     : '/assets/img/maps/marker.png',
                                    iconSize    : [26, 46],
                                    iconAnchor  : [13, 46],
                                    popupAnchor : [0, -22]
                                })
                            })
                            .addTo(this.$markersLayer)
                            .bindPopup(this.getPopupContents(data));
                        if (this.$markerById[index]) {
                            this.$markerById[index].marker = marker;
                        }
                    }, this)
                );

                if (Object.keys(this.$markersLayer.getBounds()).length) {
                    this.$map.fitBounds(this.$markersLayer.getBounds());
                }
            },

            getPopupContents: function (data) {
                return '<div class="marker-popup">' +
                    '<h5>' + data.title + '</h5>' +
                    '<p>' + this.getMarkerInfo(data) + '</p>' +
                    '<a class="btn btn--small" href="' + data.url + '" title="' + data.more_info + '">' + data.more_info + '</a>' +
                    '</div>';
            },

            showMarkersTab: function () {
                this.$filtersList.hide();
                this.$markersList.fadeIn();
            },

            showFilterTab: function () {
                this.$markersList.hide();
                this.$filtersList.fadeIn();
            },

            closeInfo: function () {
                $('.marker-info').hide();
                this.showMarkersTab();
            },

            filterMarkers: function (category) {
                this.$markersLayer.clearLayers();

                var filteredList = [];

                // Check if categories match the category_ids array from the poi list.
                this.$placesOfInterest.forEach(function (poi) {
                    if (poi.category_ids.some(function (item) {
                        return category.includes(String(item));
                    })) {
                        filteredList.push(poi);
                    }
                });

                // Add the newly filtered markers to the layer
                this.addMarkersToMap(filteredList);

                return filteredList;
            },

            onSubmitFilterForm: function () {
                var checked = [];

                var $markersListEl = $('[data-map-markers-list]');

                $.each($('input[type=checkbox]:checked'), function (i, data) {
                    checked.push(data.value);
                });

                var filtered = this.filterMarkers(checked);

                // Clear list of markers unordered list
                $markersListEl.empty();

                this.$filterNoResults.hide();

                // Fill the unordered list with the filtered markers
                // if filter is empty, give every place of interest
                this.fillSidebarWithPlacesOfInterest(
                    $markersListEl,
                    (filtered.length === 0 && checked.length === 0) ?
                        this.$placesOfInterest : filtered
                );

                if (filtered.length === 0 && checked.length === 0) {
                    this.addMarkersToMap(this.$placesOfInterest);
                }

                // Open the tab with the markers
                this.showMarkersTab();

                if (filtered.length === 0 && checked.length > 0) {
                    this.$filterNoResults.show();
                }

                $('html, body').animate({
                    scrollTop : $markersListEl.offset().top - 150
                }, 500);
            },

            initMap: function () {
                this.$map = L.map(this.$mapId, {
                    center: [53.424183, 5.758083],
                    minZoom: 12,
                    maxZoom: 17,
                    crossOrigin: true,
                    maxBounds: this.$bounds,
                    useCache: true
                });

                // Layer tiles over the map with the above tile service.
                this.$tileLayer = L.tileLayer(this.$tile_url, {
                    attribution:
                        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    bounds: this.$bounds,
                    useCache: true,
                    crossOrigin: true
                });

                // Add both tileLayer and markersLayer to the map.
                this.$map.addLayer(this.$tileLayer);
                this.$map.addLayer(this.$markersLayer);

                // Add location functions and callbacks to map object
                this.$map.on('locationfound', $.proxy(this.onLocationFound, this));
                this.$map.on('locationerror', $.proxy(this.onLocationError, this));

                // Call locate function every 3 seconds
                setInterval($.proxy(this.locatePositionOnMap, this), 3000);
            },

            initEvents: function () {
                /**
                 * Sidebar events
                 *
                 * Click events for the buttons.
                 */
                $('.markers-btn').on(
                    'click',
                    $.proxy(function () {
                        this.showMarkersTab();
                    }, this)
                );
                $('[data-map-show-filters]').on(
                    'click',
                    $.proxy(function () {
                        this.showFilterTab();
                    }, this)
                );
                $('.close-info').on(
                    'click',
                    $.proxy(function () {
                        this.closeInfo();
                    }, this)
                );

                $('.form-filter').on(
                    'submit',
                    $.proxy(function (e) {
                        e.preventDefault();

                        this.onSubmitFilterForm();
                    }, this)
                );

                /**
                 * Seeding events
                 *
                 * onSeedProgress: Display seed on progressbar.
                 *
                 * onSeedEnd:
                 *  event which fires when database is seeded
                 */
                this.$tileLayer.on(
                    'seedprogress',
                    $.proxy(function (seedData) {
                        this.$percentage = Math.floor(
                            ((seedData.queueLength - seedData.remainingLength)
                                / seedData.queueLength) * 100
                        );
                        this.$downloadProgressBar.css('width', this.$percentage);
                        this.$downloadProgressBar.text(this.$percentage);
                    }, this)
                );

                this.$tileLayer.on(
                    'seedend',
                    $.proxy(function () {
                        this.setDownloadStatus('done-downloading');
                        this.$percentage = 100;
                        this.$downloadProgressBar.css('width', this.$percentage);
                        this.$downloadProgressBar.text(this.$percentage + '%');
                    }, this)
                );

                // Click event for download maps button
                this.downloadMap.click(
                    $.proxy(function () {
                        this.seed();
                    }, this)
                );
            },

            setDownloadStatus: function (status) {
                localStorage.setItem('download', status);
                this.downloadMap.removeClass();
                this.downloadMap.addClass('btn ' + status);

                if (status === 'in-progress') {
                    this.$tileLayer.seed(this.$bbox, 12, 17);
                    if (!$('.spinner-border').length > 0) {
                        this.downloadMap.append($('<span class="spinner-border" role="status" aria-hidden="true"></span>'));
                    }
                }

                if (status === 'done-downloading') {
                    // Remove the spinner
                    if ($('.spinner-border').length > 0) {
                        $('.spinner-border').remove();
                    }
                }
            },

            /**
             * Initializes download button.
             */
            initDownload: function () {
                // Check if there localstorage variable is set
                if (localStorage.download === null || localStorage.download === '') {
                    return;
                }

                switch (localStorage.download) {
                case 'not-started-downloading':
                    this.setDownloadStatus('not-started-downloading');
                    break;
                case 'in-progress':
                    this.setDownloadStatus('in-progress');
                    break;
                case 'done-downloading':
                    this.setDownloadStatus('done-downloading');
                    break;
                default:
                    this.setDownloadStatus('not-started-downloading');
                    break;
                }
            },

            init: function () {
                this.initMap();
                this.initEvents();

                fetch('/api/v1/maps/locations?parents=' + $('#' + this.$mapId).data('parents'), {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + this.$authHeader
                    }
                }).then(
                    $.proxy(function (res) {
                        return res.json();
                    }, this)
                ).then(
                    $.proxy(function (result) {
                        this.$placesOfInterest = result.data;
                        this.addMarkersToMap(this.$placesOfInterest);
                    }, this)
                );
                this.$map.fitBounds(this.$bounds, 15);
                this.$map.setMinZoom(this.$map.getBoundsZoom(this.$map.options.maxBounds));
                this.initDownload();
            }
        };

        /**
         * Added a different check if document is ready
         * because sterc.js already has a window.onload.
         */
        $(document).ready(function () {
            if ($('#main-map').length > 0) {
                Map.init();
            }
        });
    }(jQuery)
);
