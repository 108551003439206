/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
var moment = require('moment');

import 'daterangepicker/daterangepicker';

(
    function ($) {
        var Searchbox = {
            lang        : $('html').attr('lang') || 'nl',
            context     : $('html').attr('data-context') || 'web',
            $container  : $('[data-searchbox-wrapper]'),

            days        : {
                nl          : ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
                de          : ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                en          : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
            },

            cancelLabel: {
                nl          : 'Sluiten',
                de          : 'Nah dran',
                en          : 'Close'
            },

            applyLabel: {
                nl          : 'Opslaan',
                de          : 'Speichern',
                en          : 'Save'
            },

            months      : {
                nl          : ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
                de          : ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                en          : ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            },

            initDatePickers: function () {
                var $element = $('[data-datepicker]');
                var lang = $('html').attr('lang') || 'nl';
                var daysOfWeek = Searchbox.days[lang];
                var monthNames = Searchbox.months[lang];

                var datePickerOptions = {
                    autoApply       : true,
                    minDate         : moment(),
                    autoUpdateInput : false,
                    locale          : {
                        format      : 'DD-MM-YYYY',
                        firstDay    : 1,
                        daysOfWeek  : daysOfWeek,
                        monthNames  : monthNames,
                        cancelLabel : Searchbox.cancelLabel[lang],
                        applyLabel  : Searchbox.applyLabel[lang]

                    }
                };

                $element.each(function (index, e) {
                    $(e).daterangepicker(datePickerOptions);

                    $(e).on('apply.daterangepicker', function (ev, picker) {
                        var start           = picker.startDate;
                        var end             = picker.endDate,
                            $pickerParent  = $(picker.element).parent();

                        $('input[name="arrivalDate"]', $pickerParent).val(start.format('YYYY-MM-DD'));
                        $('input[name="departureDate"]', $pickerParent).val(end.format('YYYY-MM-DD'));
                        $('input[name="month"]', $pickerParent).val(end.format('YYYY-MM'));
                        $('input[name="day-of-arrival"]', $pickerParent).val(end.startOf('date').format('X'));

                        if (picker.singleDatePicker) {
                            $(picker.element).val(start.format('DD-MM-YYYY'));
                        } else {
                            $(picker.element).val(start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY'));
                        }
                    });
                });
            },

            /**
             * Toggle island form.
             *
             * @param event
             * @returns {boolean}
             */
            onHandleIsland: function (event) {
                $(document.body).css({ cursor : 'wait' });

                $.ajax({
                    type        : 'POST',
                    url         : '/assets/components/site/connector.php',
                    dataType    : 'json',
                    data        : {
                        action      : 'web/tor/searchbox',
                        lang        : this.lang,
                        context     : this.context,
                        island      : $(event.currentTarget).val()
                    },
                    success    : $.proxy(function (result) {
                        if (result.results.output) {
                            // Targeting wrapper directly instead of this.$container,
                            // so multiple elements are updated
                            $('[data-searchbox-wrapper]').html(result.results.output);

                            this.initDatePickers();
                        }

                        $(document.body).css({ cursor : 'default' });
                    }, this)
                });
            },

            onSelectTravelGroup: function (element) {
                var $element = $(element.currentTarget);
                $(element.currentTarget).toggleClass('active');
                $(element.currentTarget).siblings('.travelgroup-options').toggleClass('active');

                $('body').toggleClass('primary-travelgroup-active');

                $element.next().find('input[type=number]').trigger('change');

                return false;
            },

            onChangeTravelGroupInput: function (element) {
                var $parentGroup = $(element.currentTarget).parents('[data-travelgroup-wrapper]');

                if ($parentGroup.length > 0) {
                    var $button = $('[data-searchbox-travelgroup]', $parentGroup);
                    var values  = [];

                    $('input[type="number"]', $parentGroup).each(function (index, value) {
                        var $value = $(value);
                        console.log($value);

                        if (parseInt($value.val(), 10) > 0) {
                            values.push($value.val() + ' ' + $value.attr('aria-label'));
                        }
                    });

                    if (values.length === 0) {
                        values.push($button.attr('aria-label'));
                    }

                    $button.attr('title', values.join(' - ')).text(values.join(' - '));
                }
            },

            onHideTravelGroup: function (element) {
                if (!$(element.target).closest('.travelgroup-options').length || $(element.target).hasClass('btn-select-travelgroup') || $(element.target).hasClass('btn--save')) {
                    $('[data-searchbox-travelgroup]').removeClass('active');
                    $('.travelgroup-options').removeClass('active');
                    $('body').removeClass('primary-travelgroup-active');
                }
            },

            onChangeNumberTravelGroup: function (element) {
                var $element = $(element.currentTarget),
                    $target = $element.parent().find('[data-btn-value]'),
                    value = parseInt($target.val(), 10);

                if ($element.data('btn-number') === 1) {
                    value += 1;

                    $target.val(value).trigger('change');
                } else if (value > 0) {
                    value -= 1;

                    $target.val(value).trigger('change');
                }

                return false;
            },

            init: function () {
                this.initDatePickers();
            }
        };

        $(document).on({
            change: $.proxy(Searchbox, 'onHandleIsland')
        }, '[data-searchbox-wrapper] [name="island"]');

        $(document).on({
            click: $.proxy(Searchbox, 'onSelectTravelGroup')
        }, '[data-searchbox-travelgroup]');

        $(document).on({
            click: $.proxy(Searchbox, 'onChangeNumberTravelGroup')
        }, '[data-btn-number]');

        $(document).on({
            change: $.proxy(Searchbox, 'onChangeTravelGroupInput')
        }, '.travelgroup-item input');

        $(document).on({
            click: $.proxy(Searchbox, 'onHideTravelGroup')
        }, '[data-close-groupoverlay]');

        $(document).on({
            click: $.proxy(Searchbox, 'onHideTravelGroup')
        }, 'body');

        Searchbox.init();
    }(jQuery)
);
