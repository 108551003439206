/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
window.SiteApp = (
    function ($) {
        var SiteApp = {
            $body       : $('html'),
            storage     : window.localStorage,
            userAgent   : navigator.userAgent || navigator.vendor,
            isNative    : (navigator.userAgent || navigator.vendor).indexOf('gonative') !== -1,

            /**
             * Init the default functions of the app.
             *
             * For example add a class to the HTML when it the current visitor uses the app.
             */
            initApp: function () {
                this.$body
                    .toggleClass('gonative', this.isNative)
                    .toggleClass('os-windows-phone', this.getOS() === 'Windows Phone')
                    .toggleClass('os-android', this.getOS() === 'Android')
                    .toggleClass('os-ios', this.getOS() === 'iOS');
            },

            /**
             * Init the message functions of the app.
             *
             * For example toggle a message to download the app.
             */
            initAppMessage: function () {
                if (!this.isNative && this.getOS() && !this.storage.getItem('app_message')) {
                    var $container  = $('[data-modal-container]');
                    var $target     = $('[data-modal-target="app-message"]');

                    if ($container.length && $target.length) {
                        $('[data-modal]', $target).on('click', $.proxy(function () {
                            this.storage.setItem('app_message', true);
                        }, this));

                        setTimeout($.proxy(function () {
                            this.$body.addClass('modal-active');

                            $container.append($target.clone(true)).addClass('active');
                        }, this), 5000);
                    }
                }
            },

            /**
             * Init the language functions of the app.
             * Turned off for now
             *
             * @param value
             */
            initAppLanguage: function (value) {
                if (this.isNative && !this.storage.getItem('app_language')) {
                    var language = value.toLowerCase();

                    if (language !== $('html').attr('lang').toLowerCase()) {
                        var $languageModal = $('[data-modal="language"]');

                        if ($languageModal[0]) {
                            $languageModal[0].click();
                        }

                        this.storage.setItem('app_language', language);
                    }
                }
            },

            /**
             * Get the current OS.
             *
             * @returns String|Null.
             */
            getOS: function () {
                if (/windows phone/i.test(this.userAgent)) {
                    return 'Windows Phone';
                }

                if (/android/i.test(this.userAgent)) {
                    return 'Android';
                }

                if (/iPad|iPhone|iPod/.test(this.userAgent) && !window.MSStream) {
                    return 'iOS';
                }

                return null;
            },

            /**
             * Init call.
             */
            init: function () {
                this.initApp();
            }
        };

        $(document).ready(function () {
            SiteApp.init();
            // SiteApp.initAppLanguage('de');
        });

        return SiteApp;
    }(jQuery)
);
