/* eslint-disable max-len, block-scoped-var */
import WadCookie from './WadCookie.js';

export default class Tracker {
    constructor() {
        this.cookie             = new WadCookie();
        this.identifierKey      = 'affiliateHandle';
        this.identifier         = 'wadden';
        this.referralKey        = 'affiliateReference';

        this.domains = [
            'texel.net',
            'vlieland.net',
            'vvvterschelling.nl', 'vvvterschelling.de', 'vvvterschelling.com',
            'vvvameland.nl', 'vvvameland.de', 'vvvameland.com',
            'vvvschiermonnikoog.nl', 'vvvschiermonnikoog.de', 'vvvschiermonnikoog.com',
            'texel.travelbase.nl', 'vlieland.travelbase.nl', 'terschelling.travelbase.nl', 'ameland.travelbase.nl', 'schiermonnikoog.travelbase.nl'
        ];

        this.waddenDomains = ['wadden.nl', 'holland-nordseeinseln.de', 'dutchwaddenislands.com', 'wadden.travelbase.nl'];

        /* Add wadden domains to the domains array. */
        this.domains = this.domains.concat(this.waddenDomains);
    }

    /**
     * Initialize tracking code.
     */
    init() {
        /* If is wadden domain, always append tracking code. */
        if (this.isWaddenDomain(window.location.host)) {
            this.appendTrackingToElements();
        } else {
            const url = new URL(window.location.href);
            if (url.searchParams.has(this.referralKey)) {
                this.cookie.setValue('referral', url.searchParams.get(this.referralKey));
            }

            /* If referrer is wadden domain and cookie exists, then extend the cookie. */
            var referrerHost = this.getHostFromLink(document.referrer);
            if (this.cookie.exists() && referrerHost && this.isWaddenDomain(referrerHost)) {
                this.cookie.extend();
            }

            /* If cookie is not set and get parameter is provided, then set cookie. */
            if (!this.cookie.exists() && url.searchParams.has(this.identifierKey) && url.searchParams.get(this.identifierKey) === this.identifier) {
                this.cookie.set();
            }

            /* If cookie is set, then append tracking code to elements. */
            if (this.cookie.exists()) {
                this.appendTrackingToElements();
            }
        }

        window.wadtracking = this;
    }

    /**
     * Clean host.
     * @param {string} host
     * @returns {string}
     */
    static cleanHost(host) {
        return host.replace(/www.|staging./g, '');
    }

    /**
     * Determine if provided host is a wadden domain.
     * @param {string} host
     * @returns {boolean}
     */
    isWaddenDomain(host) {
        var cleanHost = this.constructor.cleanHost(host);

        return this.waddenDomains.includes(cleanHost);
    }

    /**
     * Determine if provided host is a tracked domain.
     * @param {string} host
     * @returns {boolean}
     */
    isTrackedDomain(host) {
        var cleanHost = this.constructor.cleanHost(host);

        return this.domains.includes(cleanHost);
    }

    /**
     * Determine if provided host is the current host.
     * @param {string} linkHost
     * @returns {boolean}
     */
    isCurrentDomain(linkHost) {
        var curHost = this.constructor.cleanHost(window.location.host);

        return curHost === linkHost;
    }

    /**
     * Append tracking code to urls & form actions.
     */
    appendTrackingToElements() {
        var host = '';
        var i = 0;

        var anchors = document.getElementsByTagName('a');
        for (i = 0; i < anchors.length; i++) {
            host = this.getHostFromLink(anchors[i].href);
            if (host && this.isTrackedDomain(host) && !this.isWaddenDomain(host) && !this.isCurrentDomain(host)) {
                anchors[i].href = this.addTrackingToString(anchors[i].href);
            }
        }

        var forms = document.getElementsByTagName('form');
        for (i = 0; i < forms.length; i++) {
            host = this.getHostFromLink(forms[i].action);
            if (host && this.isTrackedDomain(host) && !this.isWaddenDomain(host) && !this.isCurrentDomain(host)) {
                forms[i].action = this.addTrackingToString(forms[i].action);

                /* If host of form action is travelbase.nl, prepend hidden input field with name affiliateHandle and value Wadden. */
                if (host.includes('travelbase.nl')) {
                    var input   = document.createElement('input');
                    input.type  = 'hidden';
                    input.name  = this.identifierKey;
                    input.value = this.identifier;

                    forms[i].prepend(input);
                }
            }
        }
    }

    /**
     * Add tracking code to string:
     * domain.tld/?aff=wadden
     *
     * In case of google advertisement:
     * domain.tld/?aff=wadden&aff_ref=google
     */
    addTrackingToString(string) {
        const currentUrl = new URL(window.location.href);
        const url        = new URL(string);

        /* Add identifier to string: ?aff=wadden. */
        url.searchParams.append(this.identifierKey, this.identifier);

        /* If current url contains gclid parameter, add &aff_ref=google. */
        if (this.isWaddenDomain(window.location.host) && currentUrl.searchParams.has('gclid')) {
            url.searchParams.append(this.referralKey, 'google');
        } else if (this.cookie.getValue('referral') === 'google') {
            url.searchParams.append(this.referralKey, 'google');
        }

        return url.toString();
    }

    /**
     *
     * @param {string} link
     * @returns
     */
    getHostFromLink(link) {
        var host = '';

        if (link) {
            var url = new URL(link);

            if (url.host) {
                host = this.constructor.cleanHost(url.host);
            }
        }

        return host;
    }
}
