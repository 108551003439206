/* eslint-disable no-new, max-len */
/* eslint-disable consistent-return */

import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Tracker from './wadtracking/src/Tracker';

import {
    Swiper, Autoplay, Pagination, Navigation, EffectFade
} from 'swiper';

import 'lightcase/vendor/jQuery/jquery.events.touch.js';
import 'lightcase';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

Swiper.use([Autoplay, Pagination, Navigation, EffectFade]);

(
    function ($) {
        var Site = {
            $body: $('html'),
            $searchContainer: $('.header .header__search'),
            $searchSuggestionsContainer: $('.header .search-suggestions'),

            $animateOnScroll: $('[data-animate]'),
            $animateFixedOnScroll: $('[data-animate-fixed]'),

            $btnToTop: $('[data-scroll-to-top]'),
            $lightCase: $('a[data-rel^=lightcase]'),

            $mapsSmall: $('.maps-small'),
            $mapsFilter: $('.maps-filter'),

            $sliderHero: $('.swiper--hero-slider'),
            $sliderGallery: $('.swiper--gallery-slider'),
            $sliderCategories: $('.swiper--categories-slider'),
            $sliderProducts: $('.swiper--products-slider'),

            $anchorNavigation: $('.navigation--anchor'),

            $responsiveTables: $('table:not(.not-responsive)'),
            $responsiveEmbedVideos: $('iframe[src*="youtube"], iframe[src*="vimeo"]'),

            disableLazyLoadForMobile: function () {
                function isMobileDevice() {
                    return /Mobi|Android/i.test(navigator.userAgent);
                }

                if (isMobileDevice()) {
                    const lazyImages = document.querySelectorAll('img[loading="lazy"]');
                    lazyImages.forEach((img) => {
                        img.removeAttribute('loading');
                    });
                }
            },

            /**
             * Toggle the search form in the header.
             *
             * @param element
             */
            onToggleSearchForm: function (element) {
                var $input = $('input', this.$searchContainer);

                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-search-active');

                if ($input.length) {
                    if (this.$body.hasClass('primary-search-active')) {
                        setTimeout($.proxy(function () {
                            $input.focus();
                        }, this), 500);
                    } else {
                        $input.blur();
                    }
                }

                return false;
            },

            /**
             * Check the search form for an empty value.
             *
             * @param element
             */
            onSubmitSearchForm: function (element) {
                var $input = $('input', $(element.currentTarget));

                if ($input.val() === '' || $input.val() === $input.attr('placeholder')) {
                    alert($(element.currentTarget).attr('data-alert'));

                    return false;
                }

                return true;
            },

            /**
             * Init the products slider.
             */
            initSliderProducts: function () {
                this.$sliderProducts.each(function (i, slider) {
                    var $slider = $(slider);

                    new Swiper(slider, {
                        slidesPerView   : 'auto',
                        navigation      : {
                            nextEl          : $('.swiper-button-next', $slider)[0],
                            prevEl          : $('.swiper-button-prev', $slider)[0]
                        }
                    });
                });
            },

            /**
             * Check the search form suggestions.
             *
             * @param element
             */
            onChangeSearchForm: function (element) {
                var value       = $(element.currentTarget).val();
                var $form       = $(element.currentTarget).parent('.form-search');
                var $container  = $('.search-suggestions', $form);

                if ($container.length) {
                    $container.empty().removeClass('active');
                } else {
                    $container  = $('<ul class="search-suggestions" />').appendTo($form);
                }

                $.ajax({
                    url         : '/assets/components/simplesearch/connector.php?action=web/autosuggestions&search=' + value,
                    dataType    : 'JSON',
                    complete    : $.proxy(function (result) {
                        var $suggestions = [];

                        if (result.responseJSON.results.length) {
                            $(result.responseJSON.results).each(function (idx, suggestion) {
                                $suggestions.push($('<li>').append(
                                    $('<a href="#" title="' + suggestion + '">' + suggestion + '</a>').bind('click', $.proxy(function (event) {
                                        $('input', this.$searchContainer).val($(event.currentTarget).attr('title'));

                                        this.$searchContainer.submit();

                                        return false;
                                    }, this))
                                ));
                            });
                        }

                        if ($suggestions.length > 0) {
                            $container.html($suggestions).addClass('active');
                        } else {
                            $container.html($suggestions).removeClass('active');
                        }
                    }, this)
                });
            },

            /**
             * Toggle the main navigation.
             *
             * @param element
             */
            onToggleNavigation: function (element) {
                $(element.currentTarget).toggleClass('active');

                this.$body.toggleClass('primary-navigation-active');

                $('.nav-toggle').attr('aria-expanded', (_, attr) => attr === 'false' ? 'true' : 'false');

                return false;
            },

            /**
             * Toggle sub navigation in the main navigation.
             *
             * @param element
             */
            onToggleSubNavigation: function (element) {
                var screenWidth = window.screen.width;

                if (screenWidth < 1200) {
                    $(element.currentTarget).parent('li').toggleClass('subnav-active');
                    $(element.currentTarget).find('> ul').stop().slideToggle();
                    return false;
                }
            },

            /**
             * Handle the subnav overview first item visibility
             * If there are less than 4 element, hide item-overview link
             */
            initSubnavOverview: function () {
                $('[data-subnav-item-overview]').each(function (index, element) {
                    var subItemCount = $(this).siblings('li').length;

                    if (subItemCount < 4) {
                        $(element).hide();
                    }
                });
            },

            /**
             * Scroll to the top of the page.
             */
            onHandleScrollToTop: function () {
                $('html,body').animate({
                    scrollTop: 0
                });

                return false;
            },

            /**
             * Toggle social share.
             *
             * @param element.
             */
            onToggleSocialShare: function (element) {
                $(element.currentTarget).parents('.social-share').toggleClass('active');

                return false;
            },

            /**
             * Copy share URL to clipboard.
             *
             * @param element.
             */
            onHandleSocialShareUrl: function (element) {
                var copy = false;
                var $element = $(element.currentTarget);

                $element.select();

                try {
                    copy = document.execCommand('copy');
                } catch (err) {
                    copy = false;
                }

                if (copy) {
                    $element.addClass('active').val($element.attr('data-success'));
                } else {
                    $element.removeClass('active');
                }
            },

            /**
             * Click hash.
             *
             * @param element
             */
            onHandleHash: function (element) {
                var $element = $('#' + element.currentTarget.hash.substr(1));

                if ($element.length) {
                    $('html, body').animate({
                        scrollTop : $element.offset().top - 50
                    }, 500);
                }
            },

            /**
             * Toggle modal view
             */
            onToggleModal: function (element) {
                var $element    = $(element.currentTarget);
                var $container  = $('[data-modal-container]');
                var $target     = $('[data-modal-target="' + $element.data('modal') + '"]');

                if ($target.length) {
                    Site.$body.addClass('modal-active');

                    $container.append($target.clone(true)).addClass('active');
                } else {
                    Site.$body.removeClass('modal-active');

                    $container.empty().removeClass('active');
                }

                return false;
            },

            /**
             * On toggle filter
             */
            onToggleFilter: function (element) {
                $(element.currentTarget).parents('.filter').toggleClass('active');

                this.$body.toggleClass('primary-filter-active');

                return false;
            },

            /**
             * Detect window scroll set body classes and animate blocks.
             */
            initWindowScroll: function () {
                var $window = $(window);

                $window.scroll($.proxy(function () {
                    var currentScroll = $window.scrollTop();

                    this.$body.toggleClass('header-fixed', currentScroll > $('header').outerHeight());

                    this.$btnToTop.toggleClass('active', currentScroll > $window.height());
                }, this)).trigger('scroll');
            },

            /**
             * Init Lightcase.
             */
            initLightCase: function () {
                this.$lightCase.lightcase({
                    showSequenceInfo        : false,
                    fullscreenModeForMobile : true,
                    swipe                   : true,
                    showTitle               : false
                });
            },

            /**
             * Init the hero slider.
             */
            initHeroSlider: function () {
                this.$sliderHero.each(function (index, slider) {
                    var $slider = $(slider);

                    new Swiper($slider[0], {
                        effect          : 'fade',
                        autoplay        : {
                            delay           : 5000
                        }
                    });
                });
            },

            /**
             * Init de Gallery slider
             */
            initGallerySlider: function () {
                
                if (window.screen.width <= 767) {
                    this.$sliderGallery.each(function (index, slider) {
                        var $slider = $(slider);

                        new Swiper($slider[0], {
                            autoplay        : {
                                delay           : 5000 
                            },
                            navigation      : {
                                nextEl          : $('.swiper-button-next', $slider)[0],
                                prevEl          : $('.swiper-button-prev', $slider)[0]
                            },
                            slidesPerView   : 'auto',
                            loop: false,
                            watchOverflow: true,
                            initialSlide: 0,
                            observer: true,
                            observeParents: true,
                        });
                    });
                }
            },

            /**
             * Init the categories slider.
             */
            initSliderCategories: function () {
                this.$sliderCategories.each(function (index, slider) {
                    var $slider = $(slider);

                    new Swiper($slider[0], {
                        slidesPerView   : 'auto',
                        navigation      : {
                            nextEl          : $('.swiper-button-next', $slider)[0],
                            prevEl          : $('.swiper-button-prev', $slider)[0]
                        }
                    });
                });
            },

            /**
             * Init the reviews.
             */
            initReviews: function () {
                $('[data-reviews]').each(function (i, element) {
                    var $element    = $(element);
                    var $languages  = $('[data-review-language]', $element);
                    var $reviews = $('[data-review]', $element);

                    $languages.on('click', function (event) {
                        var $language = $(event.currentTarget);

                        $languages.removeClass('active').filter($language).addClass('active');

                        $reviews.each(function (ii, review) {
                            var $review = $(review);

                            if ($language.attr('data-review-language') === 'all') {
                                $review.show();
                            } else if ($language.attr('data-review-language') === $review.attr('data-review')) {
                                $review.show();
                            } else {
                                $review.hide();
                            }
                        });

                        return false;
                    }).filter(':first').trigger('click');
                });
            },

            /**
             * Init the maps.
             */
            initMaps: function () {
                var latitude = 53.441170;
                var longitude = 5.773910;

                /* Overwriten based on address TV for each island. */
                if (this.$mapsFilter.data('latitude') !== 'undefined' && this.$mapsFilter.data('latitude') !== '') {
                    latitude = this.$mapsFilter.data('latitude');
                    longitude = this.$mapsFilter.data('longitude');
                }

                this.$mapsSmall.Maps({
                    center      : {
                        lat         : latitude,
                        lng         : longitude
                    },
                    markerIcon  : {
                        iconUrl     : '/assets/img/maps/marker.png',
                        iconSize    : [34, 50],
                        iconAnchor  : [17, 50]
                    }
                });

                var $mapsFilter = this.$mapsFilter.Maps({
                    center      : {
                        lat         : latitude,
                        lng         : longitude
                    },
                    markerIcon  : {
                        iconUrl     : '/assets/img/maps/marker.png',
                        iconSize    : [34, 50],
                        iconAnchor  : [17, 50]
                    }
                });

                if ($mapsFilter.length >= 1) {
                    var $mapsFilterForm = $('[data-maps-filter]');

                    if ($mapsFilterForm.length >= 1) {
                        $('a', $mapsFilterForm.parent()).on('click', function (event) {
                            $('input[name="date_start"]', $mapsFilterForm).val($(event.currentTarget).attr('data-date-start'));
                            $('input[name="date_end"]', $mapsFilterForm).val($(event.currentTarget).attr('data-date-end'));

                            $mapsFilterForm.trigger('submit');

                            return false;
                        });

                        $mapsFilterForm.on('submit', function (element) {
                            var $form       = $(element.currentTarget);
                            var type        = $form.attr('data-maps-filter');
                            var params      = {
                                action          : 'web/overview/list',
                                type            : type
                            };

                            $form.attr('data-maps-filter-params').split(',').forEach(function (param) {
                                params[param.split(':', 2)[0]] = param.split(':', 2)[1];
                            });

                            if (type === 'events') {
                                params.date_start   = $('input[name="date_start"]', $form).val();
                                params.date_end     = $('input[name="date_end"]', $form).val();
                                params.theme        = [];

                                $('input[name="theme[]"]:checked', $form).each(function (i, checkbox) {
                                    params.theme.push($(checkbox).val());
                                });
                            }

                            $.ajax({
                                url         : '/assets/components/site/connector.php?' + $.param(params),
                                dataType    : 'JSON',
                                complete    : $.proxy(function (results) {
                                    if (results.responseJSON) {
                                        $mapsFilter.trigger('reset.maps');

                                        results.responseJSON.output.forEach(function (result) {
                                            if (result.address.latitude && result.address.longitude) {
                                                $mapsFilter.trigger('setMarker.maps', [result.address.latitude, result.address.longitude, {
                                                    popup : result.popup || ''
                                                }]);
                                            }
                                        });

                                        $mapsFilter.trigger('bindZoomMarkers.maps');
                                    }
                                }, this)
                            });

                            return false;
                        }).trigger('submit');
                    }
                }
            },

            /**
             * Init the content toggles.
             */
            initContentToggles: function () {
                $('[data-toggle-content]').each(function (i, element) {
                    var $element    = $(element);
                    var collapsed   = $element.attr('data-toggle-content-collapsed') || 'Lees meer';
                    var expanded    = $element.attr('data-toggle-content-expanded') || 'Lees minder';

                    $element.append(
                        $('<button title="' + collapsed + '">' + collapsed + '</button>').on('click', function (event) {
                            if ($element.hasClass('active')) {
                                $(event.currentTarget).attr('title', collapsed).html(collapsed);
                            } else {
                                $(event.currentTarget).attr('title', expanded).html(expanded);
                            }

                            $element.toggleClass('active');
                        })
                    );
                });
            },

            /**
             * Init the responsive tables.
             */
            initResponsiveTables: function () {
                this.$responsiveTables.each(function (i, table) {
                    $(table).wrap('<div class="table-wrapper" />');
                });
            },

            /**
             * Init the responsive embed videos.
             */
            initResponsiveEmbedVideos: function () {
                this.$responsiveEmbedVideos.each(function (i, video) {
                    $(video).wrap('<div class="video-wrapper" />').wrap('<div class="video-wrapper__inner" />');
                });
            },

            /**
             * Init animate scroll
             */
            initAnimateScroll: function () {
                ScrollTrigger.create({
                    start       : 0,
                    onUpdate    : ({ direction, progress }) => {
                        if (progress === 0) {
                            this.$body.removeClass(['scroll-up', 'scroll-down']);
                        } else if (direction === 1) {
                            this.$body.removeClass('scroll-up').addClass('scroll-down');
                        } else if (direction === -1) {
                            this.$body.removeClass('scroll-down').addClass('scroll-up');
                        }
                    }
                });

                /* Animate sections */
                gsap.utils.toArray(this.$animateOnScroll).forEach((section, index) => {
                    if (index > 0) {
                        gsap.set(section, {
                            opacity : 0,
                            y       : 50
                        });

                        gsap.to(section, {
                            scrollTrigger   : {
                                trigger         : section,
                                start           : 'top bottom'
                            },
                            y               : 0,
                            opacity         : 1,
                            duration        : 1
                        });
                    }
                });

                /* Animate 'sticky' sections */
                gsap.utils.toArray(this.$animateFixedOnScroll).forEach((section) => {
                    gsap.timeline({
                        scrollTrigger   : {
                            trigger         : section,
                            endTrigger      : 'html',
                            start           : 'bottom top',
                            end             : 'bottom top',
                            toggleClass     : 'fixed'
                        }
                    });
                });
            },

            /**
             * Init wadden conversion tracker js.
             */
            initTracker: function () {
                var tracker = new Tracker();
                tracker.init();
            },

            /**
             * Init the anchor navigation.
             */
            initAnchorNavigation: function () {
                var $window = $(window);

                this.$anchorNavigation.each($.proxy(function (i, navigation) {
                    var $navigation         = $(navigation);
                    var $navigationItems    = $('li a', $navigation);
                    var $anchors            = [];
                    var $targets            = [];

                    $navigationItems.each($.proxy(function (ii, anchor) {
                        var $anchor  = $(anchor);
                        var $target  = $('[data-anchor="' + $anchor.attr('href').split('#')[1] + '"]');

                        if ($target.length) {
                            $anchor.on('click', $.proxy(function () {
                                $('html, body').animate({
                                    scrollTop : $target.offset().top - 160
                                }, 500);

                                $navigationItems.parent('li').removeClass('active');
                                $anchor.parent('li').addClass('active');

                                return false;
                            }, this));

                            $anchors.push($anchor);
                            $targets.push($target);
                        }
                    }, this));

                    $window.on('scroll', $.proxy(function () {
                        var scrollTop = $window.scrollTop();

                        $targets.forEach($.proxy(function ($target, ii) {
                            var offset = $target.offset().top - 100;
                            var height = $target.outerHeight(true);

                            if (scrollTop >= offset && scrollTop <= offset + height) {
                                $anchors[ii].parent('li').addClass('active');
                            } else {
                                $anchors[ii].parent('li').removeClass('active');
                            }
                        }, this));
                    }, this)).trigger('scroll');
                }, this));

                /* Executed on page load with URL containing an anchor tag. */
                if (window.location.href.includes('#') &&
                    window.location.href.split('#')[1] &&
                    !window.location.href.split('#')[1].includes('_ga')
                ) {
                    if ($('[data-faq-question="' + window.location.href.split('#')[1] + '"]').length > 0) {
                        $('[data-faq-question="' + window.location.href.split('#')[1] + '"]').click();
                    }

                    var target = $('#' + window.location.href.split('#')[1]);
                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top - 150
                        }, 1000);
                        return false;
                    }
                }
            },

            /**
             * Init call.
             */
            init: function () {
                this.initWindowScroll();

                this.initLightCase();

                this.initHeroSlider();
                this.initSliderCategories();
                this.initSliderProducts();

                this.initReviews();

                this.initMaps();
                this.initContentToggles();

                this.initResponsiveTables();
                this.initResponsiveEmbedVideos();

                this.initAnimateScroll();
                this.initAnchorNavigation();

                this.initSubnavOverview();
                this.initTracker();
                this.disableLazyLoadForMobile();
                this.initGallerySlider();

                if (window.location.hash) {
                    $('.navigation--anchor a').each(function (index, element) {
                        if ($(element).attr('href').includes(window.location.hash)) {
                            $(element).trigger('click');
                        }
                    });
                }
            }
        };

        $(document).on({
            click: $.proxy(Site, 'onToggleSearchForm')
        }, '[data-search-toggle]');

        $(document).on({
            submit: $.proxy(Site, 'onSubmitSearchForm')
        }, '.header .form-search');

        $(document).on({
            keyup: $.proxy(Site, 'onChangeSearchForm')
        }, 'header .form-search input[type="text"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleNavigation')
        }, '[data-nav-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSubNavigation')
        }, '.has-subnav [data-toggle-subnav]');

        $(document).on({
            click: $.proxy(Site, 'onHandleScrollToTop')
        }, '[data-scroll-to-top]');

        $(document).on({
            click: $.proxy(Site, 'onToggleSocialShare')
        }, '[data-social-share-toggle]');

        $(document).on({
            click: $.proxy(Site, 'onHandleSocialShareUrl')
        }, '[data-social-share-url]');

        $(document).on({
            click: $.proxy(Site, 'onHandleHash')
        }, 'a[href*="#"]');

        $(document).on({
            click: $.proxy(Site, 'onToggleModal')
        }, '[data-modal]');

        $(document).on({
            click: $.proxy(Site, 'onToggleFilter')
        }, '[data-toggle-filter]');

        window.onload = function () {
            Site.init();
        };
    }(jQuery)
);
