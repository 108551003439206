/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
(
    function ($) {
        var deferredPrompt;

        var Pwa = {
            $installButtonWrapper: $('[data-pwa-install-wrapper]'),
            $installButton: $('[data-pwa-install-button]'),
            $installModalWindow: $('[data-pwa-install-modal]'),
            $installedRelatedApps: [],

            // Check for platforms
            // iOS platforms don't have native install function with pwa's
            // Desktops shouldn't see the install button
            isApplePlatform: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
            isAndroidPlatform: (/(android)/i.test(navigator.userAgent) || navigator.platform.toLowerCase().indexOf('android') > -1) && !window.MSStream,
            isRunningStandalone: window.matchMedia('(display-mode: standalone)').matches,

            installHideCookieName: 'pwa_install_hide',

            hideInstallPromotion: function () {
                if (this.$installButtonWrapper) {
                    this.$installButtonWrapper.removeClass('active');
                    $('html').removeClass('banner-active');
                }
            },

            showInstallPromotion: function () {
                if (this.$installButtonWrapper &&
                    !this.shouldHideInstallPromotion() &&
                    navigator.userAgent.indexOf('gonative') === -1
                ) {
                    this.$installButtonWrapper.addClass('active');
                    $('html').addClass('banner-active');
                }
            },

            closeInstallPromotion: function () {
                this.hideInstallPromotion();
                this.setInstallHideCookie();
            },

            shouldHideInstallPromotion: function () {
                var pwaHide = this.getCookie(this.installHideCookieName);

                return !!pwaHide;
            },

            setInstallHideCookie: function () {
                var maxAge = 60 * 60 * 24 * 7;
                document.cookie = this.installHideCookieName + '=1;max-age=' + maxAge + ';path=/';
            },

            getCookie: function (name) {
                var matches = document.cookie.match(new RegExp(
                    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
                ));
                return matches ? decodeURIComponent(matches[1]) : undefined;
            },

            getInstalledApps: function () {
                return navigator.getInstalledRelatedApps();
            },

            isMobilePlatform: function () {
                return this.isApplePlatform || this.isAndroidPlatform;
            },

            initInstaller: function () {
                if ('getInstalledRelatedApps' in navigator) {
                    navigator.getInstalledRelatedApps().then(function (apps) {
                        this.$installedRelatedApps = apps;
                    });
                }

                if (navigator.userAgent.indexOf('GoNativeIOS/1.0 gonative') > -1) {
                    $('html').addClass('native-ios-active');
                }

                // Checks if user already installed the PWA.
                // If they have, don't show install button.
                if (
                    (this.$installedRelatedApps.length === 0 || this.$installedRelatedApps === [])
                    && this.isMobilePlatform()
                ) {
                    this.$installButton.on('click', $.proxy(function () {
                        if (this.isApplePlatform) {
                            $('html').addClass('is-ios');
                        } else if (this.isAndroidPlatform) {
                            deferredPrompt.prompt();
                        }
                    }, this));

                    this.showInstallPromotion();
                }

                // If pwa is opened via the app/home screen -> don't show install promotion
                // If platform is neither android or iOS    -> don't show install promotion
                if (this.isRunningStandalone || !this.isMobilePlatform()) {
                    this.hideInstallPromotion();
                }

                window.addEventListener('beforeinstallprompt', $.proxy(function (e) {
                    if (this.isMobilePlatform()) {
                        e.preventDefault();

                        deferredPrompt = e;

                        this.showInstallPromotion();
                    } else {
                        this.hideInstallPromotion();
                    }
                }, this));

                window.addEventListener('appinstalled', function () {
                    this.hideInstallPromotion();
                });
            },

            /**
             * Initialize ServiceWorker.
             */
            initServiceWorker: function () {
                var pwaEnabled = 0;

                if (parseInt(pwaEnabled, 10) === 0) {
                    return;
                }

                if (navigator.serviceWorker) {
                    navigator.serviceWorker.register('sw.js?context=' + currentContext)
                        .then(function (registration) {
                            var serviceWorker;
                            if (registration.installing) {
                                serviceWorker = registration.installing;
                            } else if (registration.waiting) {
                                serviceWorker = registration.waiting;
                            } else if (registration.active) {
                                serviceWorker = registration.active;
                            }
                        })
                        .catch(function (error) {
                            console.log('Service worker not supported, ' + error);
                        });
                } else {
                    console.log('Service worker not supported.');
                }
            }
        };

        $(document).on({
            click: $.proxy(Pwa, 'closeInstallPromotion')
        }, '[data-pwa-install-close]');

        Pwa.initInstaller();
        Pwa.initServiceWorker();
    }(jQuery)
);
