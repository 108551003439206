class WadCookie {
    constructor() {
        this.name  = 'wadc';
        this.days = 60;
        this.value = {
            referral: ''
        };

        /* If cookie is already set, then set the value to the cookie value. */
        if (this.get()) {
            this.value = this.get();
        }
    }

    /**
     * Determine if cookie has been set.
     * @returns {boolean}
     */
    exists() {
        return document.cookie.indexOf(this.name + '=') >= 0;
    }

    /**
     * Get the cookie.
     */
    get() {
        let name          = this.name + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca            = decodedCookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let content = ca[i];

            while (content.charAt(0) === ' ') {
                content = content.substring(1);
            }

            if (content.indexOf(name) === 0) {
                let value = content.substring(name.length, content.length);

                return JSON.parse(value);
            }
        }

        return '';
    }

    /**
     * Set the cookie.
     */
    set() {
        const date = new Date();

        date.setTime(date.getTime() + (this.days * 24 * 60 * 60 * 1000));

        let expires = 'expires=' + date.toUTCString();

        document.cookie = this.name + '=' + JSON.stringify(this.value) + ';' + expires + ';path=/';
    }

    /**
     * Get cookie value.
     */
    getValue(key) {
        var values = this.get();

        if (values[key]) {
            return values[key];
        }

        return '';
    }

    /**
     * Set cookie value.
     */
    setValue(key, value) {
        this.value[key] = value;
    }

    /**
     * Extend cookie by resetting the cookie.
     */
    extend() {
        this.set();
    }
}

export default WadCookie;
